//
// Layout Config
// Here you can set the demo level SASS variables and override the global variables for this demo.
// Note: To override the global variables in the demo level use the variables without "!default". More info:  https://sass-lang.com/documentation/variables
//




// State colors
$kt-state-colors: ( // Metronic states
    brand: (base: #319DB5,
        inverse: #ffffff),
    light: (base: #ffffff,
        inverse: #282a3c),
    dark: (base: #282a3c,
        inverse: #ffffff),

    // Bootstrap states
    primary: (base: #319DB5,
        inverse: #ffffff),
    success: (base: #28a745,
        inverse: #ffffff),
    info: (base: #a2a5b9,
        inverse: #ffffff),
    warning: (base: #ffb822,
        inverse: #111111),
    danger: (base: #dc3545,
        inverse: #ffffff));

// Brand Color
$kt-brand-color: #319DB5 !default;
$kt-brand-inverse-color: #ffffff !default;
$kt-brand-hover-color: #f5f6fc;

// Body
$kt-page-body-bg-color: #f9f9fc; // page body bg color

// Layout
$kt-aside-default-width: 245px;
$kt-aside-offcanvas-width: 255px;

// Page padding
$kt-page-padding: (desktop: 30px,
    mobile: 15px);

// Page container
$kt-page-container-width: 1380px; //  fixed page width
$kt-page-fluid-container-padding: 60px; // fluid page padding