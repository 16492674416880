//
// Table
//

// // SFID
// .mat-column-sfid {
//     flex: 0 0 7%;
// }


// // SIEC
// .mat-column-network {
//     flex: 0 0 4.5%;
// }

// // ADRES
// .mat-column-address {
//     flex: 0 0 9%;
// }

// // TELEFON
// .mat-column-phone {
//     flex: 0 0 7.5%;
// }

// // CENTRUM HANDLOWE 
// .mat-column-gallery {
//     flex: 0 0 5.5%;
// }

// // STATUS
// .mat-column-status {
//     flex: 0 0 7%;
// }

// // STATUS PS
// .mat-column-posStatus {
//     flex: 0 0 7%;
// }

// // KOMENTARZ
// .mat-column-posStatusDesc {
//     flex: 0 0 7.5%;
// }

// // TAG
// .mat-column-extendedStatus {
//     flex: 0 0 7%;
// }

// // IP 
// .mat-column-ip {
//     word-break: break-word;
//     flex: 0 0 7%;
// }

// // MODEL
// .mat-column-model {
//     flex: 0 0 10%;
// }

// // OSTATNI RAPORT
// .mat-column-lastReport {
//     flex: 0 0 12%;
// }

// // SOFT
// .mat-column-soft {
//     flex: 0 0 7%;
// }

// // EMAIL
// .mat-column-email {
//     word-break: break-word;
// }


// // LICZNIKI
// .mat-column-sensorsCount {
//     flex: 0 0 5%;
// }

// // DNI %
// .mat-column-reportingDaysPercentage {
//     flex: 0 0 5%;
// }

// // RAPORTY %
// .mat-column-reportsCountPercentage {
//     flex: 0 0 6%;
// }

// // AKCJE
// .mat-column-actions {
//     flex: 0 0 6%;
// }

table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
}


.filter {
    display: block;
}

mat-cell,
mat-footer-cell,
mat-header-cell {
    padding: 0 5px !important;
}

mat-cell:first-of-type,
mat-footer-cell:first-of-type,
mat-header-cell:first-of-type {
    padding-left: 0 !important;
}

mat-cell:last-of-type,
mat-footer-cell:last-of-type,
mat-header-cell:last-of-type {
    padding-right: 0 !important;
}

mat-cell span {
    display: flex;
    align-items: inherit;
}

mat-table .mat-mdc-input-element {
    background-color: #f5f6fc !important;
    padding: 5px !important;
}

mat-footer-row,
mat-header-row,
mat-row {
    width: 100% !important;
}

.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
    flex: 1 1 auto !important;
    overflow-x: auto !important;
    width: inherit !important;
    word-break: break-word;
}

.mat-mdc-row, .mdc-data-table__content {
    font-weight: 300 !important;
}
/* Odpowiedzialne za nagłowek tabeli */

.mat-mdc-table .mat-header-cell {
    flex-direction: column;
}
.mat-mdc-table .mat-mdc-header-cell h6 {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(49, 157, 181, 0.1);
    width: 100%;
    height: 40px;
    word-break: break-word;
}
.mat-mdc-table .mat-mdc-header-cell, .mat-mdc-table .mat-sort-header-container {
	display: flex;
	flex-direction: column;
}
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-form-field-infix {
	min-height: 36px;
}
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-form-field-subscript-wrapper
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-select {
	display: inline-flex !important;
}
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-text-field-wrapper {
	padding: 0;
}
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-form-field-flex {
width: 100%;
position:relative;
display: flex;
align-items: stretch;
}
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
}
.mat-mdc-table .mat-mdc-header-cell .mat-mdc-form-field {
    padding-bottom: 5px;
}

.mat-mdc-table .mat-mdc-header-cell .mdc-text-field--filled .mdc-line-ripple::before {
	border-bottom-width: 0;
}

// KOLUMNA LP
.mat-column-index {
    flex: 0 0 4% !important;
}

// KOLUMNA SELECT
.mat-column-select {
    flex: 0 0 4% !important;
    overflow-x: unset !important;
}

// KOLUMNA SFID
.mat-column-sfid {
    flex: 0 0 6.5% !important;
    overflow-x: unset !important;
}

// KOLUMNA CH 
.mat-column-gallery {
    flex: 0 0 4.5% !important;
    overflow-x: unset !important;
}

// LICZNIKI
.mat-column-sensorsCount {
    flex: 0 0 6.9% !important;
    overflow-x: unset !important;
}

// KOLUMNA SELECT
.mat-column-network {
    flex: 0 0 4.5% !important;
    overflow-x: unset !important;
}

/* -- Odpowiedzialne za nagłowek tabeli */ 

@media (max-width: 991px) {
    mat-table {
        overflow-x: auto;
        width: fit-content;
    }
}

.mat-column-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}