/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "../src/assets/sass/config";
@import "../src/assets/sass/global/components/base/portlet";
@import "../src/assets/sass/global/components/base/table";

:root {
	--yoberi: #D8007A;
}
.kt-badge--yoberi {
	background-color: var(--yoberi) !important;
}
.kt-badge--black {
	background-color: #000 !important;
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}

.mat-mdc-table__wrapper--dashboard .mat-mdc-table {
	min-width: 600px !important;
	width: 100% !important;
}

.my-10 {
	margin: 10px 0;
}
.px-15 {
	padding: 0 15px;
}
.ml-auto {
	margin-left: auto;
}
.position-static {
	position: static;
}

.kt-header__brand-logo img {
	max-width: 260px;
}

.md-drppicker {
	left: 10px !important;

	.ranges ul li button.active {
		background-color: kt-brand-color() !important;
	}

	.btn {
		background-color: kt-brand-color() !important;
	}

	td.active {
		background-color: kt-brand-color() !important;
	}

	@media (min-width: 767px) {
		&.double {
			width: 644px !important;
		}
	}

}

// https://github.com/angular/components/issues/26000
.cdk-overlay-pane:has(.mat-mdc-select-panel) { min-width: fit-content; }

.brand-btn {
	color: kt-brand-color();
	width: 100%;
	width: fit-content;
	position: relative;
}

mat-header-cell .mat-mdc-form-field-infix {
	padding: 0px !important;
}

.kt-badge {
	background-color: transparent;
	color: #000;
}

.kt-badge.kt-badge--light {
	color: #000 !important;
	font-weight: 300;
}

.label,
.kt-badge {
	font-size: 10.5px !important;
	line-height: 13px !important;
	padding: 3px 5px !important;
	color: #fff !important;
	border-radius: .25em !important;
	font-weight: 400;
	height: auto !important;
	width: auto !important;
	min-height: auto !important;
}

mat-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
}

.dateFilter h5 {
	color: #434349;
	font-weight: 500;
	font-size: 1rem;
	padding: 0 1rem 0 0;
}

.dateFilter button {
	margin-top: 10px;
}

#kt_header_mobile_toggler {
	display: none !important;
}

@media (max-width: 1024px) {
	kt-menu-horizontal {
		display: none !important;
	}
}

.clickable {
	cursor: pointer;

	&:hover {
		color: kt-brand-color();
	}
}

.comment .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
	margin: 5px;
}

.comment .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
	margin: 0 !important;
}

.mat-mdc-input-element {
	background-color: transparent;
	padding: 0;
}

kt-quick-panel {
	display: none;
}

@media (max-width: 1024px) {

	// responsywnosc tabeli
	.res-table {
		width: fit-content;
	}
}

.mat-mdc-form-field {
	width: 100%;
}

.mat-mdc-simple-snackbar-action .mat-mdc-button-wrapper {
	color: kt-brand-color();
}

kt-diagnostics .datePicker {
	display: none;
}

.kt-subheader .kt-subheader__main .kt-subheader__separator.kt-subheader__separator--v {
	border-radius: 50%;
	width: 5px !important;
	height: 5px !important;
}

.kt-subheader .kt-subheader__main .kt-subheader__title {
	font-size: 0.9rem !important;
	text-transform: uppercase;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	z-index: 99999 !important;
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
	color: #000 !important;
}

.hideColumn {
	// visibility: hidden;
	// opacity: 0;
	flex: 0 0 0% !important;
	display: none;
}

.ktShowHidePassword {
	float: right;
	position: absolute;
	right: 0;
}

.base {
	color: kt-brand-color();
}

.table-filter {
	display: inline-block;
	margin-right: 15px;
}

.inputfile {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 34px;
	cursor: pointer;
}

.input-btn:hover {
	background-color: kt-brand-color();
}

.input-btn:hover .btn {
	color: #fff;
}

.multiple-buttons {
	display: inline-flex;
}

mat-cell.clickable,
a {
	color: kt-brand-color() !important;
	cursor: pointer;
}

.mat-mdc-sort-header-container {
	flex-direction: column;
}

.trash {
	position: absolute;
	margin-left: 15px;
	color: kt-base-color(label, 3);
	cursor: pointer;
}

.btn-brand.disabled, .btn-brand:disabled {
	color: #fff !important;
}

/* Nadpisanie domyślnych kolorów dla mdc-filled-text-field */
:root {
	--mdc-filled-text-field-caret-color: kt-brand-color();
	--mdc-filled-text-field-focus-active-indicator-color: kt-brand-color();
	--mdc-filled-text-field-focus-label-text-color: darken(kt-brand-color(), 6%);
  }